import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { Button, Spinner } from '@vp/swan'
import { useMsgModalLoadingText } from 'lib/intl/msg-modal.hooks'
import { useSiteFlowUpsell } from 'lib/site-flow'
import { useToastSomethingWentWrong } from 'lib/toast'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { windowOpen } from 'utilities/navigate.utils'
import { MailingOption } from '../benefit-selection/benefit-selection.component'

export const ActionSkip: FC<{ mailingOption: MailingOption; isCompatible?: boolean; isCompatibleReceived?: boolean }> = ({
  mailingOption,
  isCompatible,
  isCompatibleReceived,
}) => {
  const unknownErrorToast = useToastSomethingWentWrong()
  const loadingMessage = useMsgModalLoadingText()
  const { data: nextUrl, isLoading } = useSiteFlowUpsell()
  const { logError } = useLogger()

  const onNextSkip = () => {
    if (nextUrl?.url) {
      windowOpen(nextUrl.url)
    } else {
      unknownErrorToast()
      logError('Url for next page navigation from Upsell is not present', {})
    }
  }
  useEffect(() => {
    if (!isCompatible && isCompatibleReceived && nextUrl?.url) {
      windowOpen(nextUrl.url)
    }
  }, [nextUrl?.url, isCompatible, isCompatibleReceived])

  if (mailingOption === MailingOption.MAIL_IT) return null

  return (
    <Button disabled={isLoading} skin="primary" onClick={onNextSkip}>
      <FormattedMessage defaultMessage="Next" description="Button label for next page navigation" />
      {isLoading && <Spinner ml={2} mr={0} accessibleText={loadingMessage} />}
    </Button>
  )
}
