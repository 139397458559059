import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { Box, Button, H3, Icon, ModalDialog, ModalDialogBody, ModalDialogContent, ModalDialogFooter, Spinner, Typography } from '@vp/swan'
import { WorkIdPreview } from 'components/work-id-preview/work-id-preview.component'
import { WorkDetails } from 'components/work-id-preview/work-id-preview.types'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { useMsgModalLoadingText } from 'lib/intl/msg-modal.hooks'
import { useConvertToMailableWork, useIsWorkConvertible } from 'lib/mailing-svc-api'
import { useMpvIdQuery } from 'lib/msx/msx.hooks'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useSiteFlowUpsell } from 'lib/site-flow'
import { useStudioPageURL } from 'lib/site-links'
import { useToastSomethingWentWrong } from 'lib/toast'
import { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { noOp } from 'utilities/functions.utils'
import { windowOpen } from 'utilities/navigate.utils'
import { MailingOption } from '../benefit-selection/benefit-selection.component'
import classes from './action-convert.module.scss'

const slidesToShow = ['back']
export const ActionConvert: FC<{ isCompatible?: boolean; mailingOption: MailingOption }> = ({ isCompatible, mailingOption }) => {
  const [showConverted, setShowConverted] = useState<boolean>(false)
  const [convertedWorkDetails, setConvertedWorkDetails] = useState<WorkDetails>()
  const intl = useIntl()
  const { data: isConvertibleResponse } = useIsWorkConvertible()
  const unknownErrorToast = useToastSomethingWentWrong()
  const loadingMessage = useMsgModalLoadingText()
  const productInfoOption = {
    productKey: isConvertibleResponse?.mailingServicesCoreProductId,
    productVersion: isConvertibleResponse?.mailingServicesProductVersion,
  }
  const { logError } = useLogger()

  const { data: mpvId } = useMpvIdQuery(convertedWorkDetails?.product.key)
  const { data: nextUrl } = useSiteFlowUpsell({ workId: convertedWorkDetails?.workId, mpvId: mpvId ?? undefined })

  const navigateNext = (toStudio?: boolean) => {
    if (toStudio) {
      if (convertedWorkDetails) {
        windowOpen(`${studioUrl}?workId=${convertedWorkDetails.workId}`, '_self')
      } else {
        unknownErrorToast()
        logError('Navigation to studio failed as converted work details are not present', {
          productKey: productInfoOption.productKey,
          productVersion: productInfoOption.productVersion,
        })
      }
    } else {
      if (nextUrl?.url) {
        windowOpen(nextUrl.url)
      } else {
        unknownErrorToast()
        logError('Url for next page navigation from Upsell is not present', {})
      }
    }
  }
  const {
    mutationResult: { isLoading: isConvertingToMailableWork, mutateAsync: convertToMailableWork },
  } = useConvertToMailableWork()
  const studioUrl = useStudioPageURL()
  const workId = useWorkIdQueryParam()

  const onNextConvert = () => {
    if (isCompatible) {
      convertToMailableWork()
        .then(res => {
          setConvertedWorkDetails(res)
          setShowConverted(true)
          newRelicAddPageAction('Converting workId to mailable workId', { result: 'success' })
        })
        .catch(() => {
          unknownErrorToast()
          newRelicAddPageAction('Converting workId to mailable workId', { result: 'error' })
          logError('Failed to convert postcard workId to mailable workId', { workId })
        })
    } else {
      unknownErrorToast()
      logError('Postcard work id is not compatible to be converted to mailable work id', { workId })
    }
  }
  if (mailingOption === MailingOption.IGNORE) return null

  return (
    <>
      <Button disabled={isConvertingToMailableWork} skin="primary" onClick={onNextConvert}>
        <FormattedMessage defaultMessage="Next" description="Button label for next page navigation" />
        {isConvertingToMailableWork && <Spinner ml={2} mr={0} accessibleText={loadingMessage} />}
      </Button>
      <ModalDialog isOpen={showConverted} variant="panel-right" onRequestDismiss={noOp}>
        <ModalDialogContent fullBleed aria-labelledby={intl.formatMessage({ defaultMessage: 'Preview converted postcard' })} style={{ width: '500px' }}>
          <ModalDialogBody>
            <Box className={classes.previewContainer}>
              <H3 fontWeight="bold">
                <FormattedMessage defaultMessage="Review the back of your postcard" />
              </H3>
              <Typography mt={6} fontSize={'standard'}>
                <FormattedMessage defaultMessage="The mailing address and postage area might overlap with the back design of your postcard. Does it look good?" />
              </Typography>
            </Box>
            <WorkIdPreview purpose="design.studio.review" workDetails={convertedWorkDetails || {}} sidesToShow={slidesToShow} />
          </ModalDialogBody>
          <ModalDialogFooter pinned py={4}>
            <Box align="center">
              <Button onClick={() => navigateNext(true)} mr={2}>
                <FormattedMessage defaultMessage="Edit my design" description="Action button to navigate to next page" />
              </Button>
              <Button onClick={() => navigateNext()} skin="primary">
                <FormattedMessage defaultMessage="Continue" description="Action button to navigate to next page" />
                <Icon iconType="arrowRight" />
              </Button>
            </Box>
          </ModalDialogFooter>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
