import { Button } from '@vp/swan'
import { GetQuotationModal } from 'lib/product-info'
import { useToastSomethingWentWrong } from 'lib/toast'
import { FC, PropsWithChildren, useState } from 'react'

export const UpSellGetQuotation: FC<PropsWithChildren<{ isCompatible?: boolean }>> = ({ children, isCompatible }) => {
  const [showQuotation, setShowQuotation] = useState<boolean>(false)
  const errorToast = useToastSomethingWentWrong()

  const onClick = () => {
    if (!isCompatible) {
      errorToast()
    } else {
      setShowQuotation(true)
    }
  }
  return (
    <>
      <Button skin="unstyled" onClick={onClick} style={{ textDecoration: 'underline' }}>
        {children}
      </Button>
      <GetQuotationModal show={showQuotation} onDismiss={() => setShowQuotation(false)} />
    </>
  )
}
