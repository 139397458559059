import { DiscountPrice, H3, Li, Typography, Ul } from '@vp/swan'
import { useMsgUpsellDiscount, useMsgUpsellGuaranteedDelivery, useMsgUpsellMailingBenefits } from 'lib/intl/msg-upsell-page.hooks'
import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { UpSellGetQuotation } from '../upsell-get-quotation/upsell-get-quotation.component'

function useBenefits() {
  const upsellMailingBenefits = useMsgUpsellMailingBenefits()
  const upsellGuaranteedDelivery = useMsgUpsellGuaranteedDelivery()
  const upsellDiscount = useMsgUpsellDiscount({ discountPrice: (...chunks) => <DiscountPrice>{chunks}</DiscountPrice> })
  return [
    {
      id: '1',
      message: upsellMailingBenefits,
    },
    {
      id: '2',
      message: upsellDiscount,
    },
    { id: '3', message: upsellGuaranteedDelivery },
  ]
}

export const UpsellBenefits: FC<{ isCompatible?: boolean; isCompatibleReceived?: boolean }> = ({ isCompatible, isCompatibleReceived }) => {
  const benefits = useBenefits()

  const intl = useIntl()
  const messagePart1 = intl.formatMessage({
    defaultMessage: 'See how much time and money you’ll save with our postcard mailing services.',
  })
  const buttonMessage = intl.formatMessage({ defaultMessage: 'Get a quote.' })
  const messagePart2 = intl.formatMessage({
    defaultMessage: 'Questions? Call 1-855-210-1085.',
  })
  return (
    <>
      <H3 fontWeight={'bold'}>
        <FormattedMessage defaultMessage="Want us to mail these for you?" />
      </H3>
      <Typography mt={{ md: 5, xs: 4 }} mb={{ xs: 6 }} fontWeight={'normal'}>
        {messagePart1} {isCompatibleReceived && <UpSellGetQuotation isCompatible={isCompatible}>{buttonMessage}</UpSellGetQuotation>} {messagePart2}
      </Typography>
      <Ul skin="checkmark">
        {benefits.map(benefit => (
          <Li key={benefit.id}>{benefit.message}</Li>
        ))}
      </Ul>
    </>
  )
}
