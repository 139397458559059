import { SelectionSet, SelectionSetInput, SelectionSetLabel, SelectionSetTileContents, SelectionSetTileName, SelectionSetTilePrice, Typography } from '@vp/swan'
import { useMsgUpsellNoButtonTitle, useMsgUpsellYesButtonSubTitle, useMsgUpsellYesButtonTitle } from 'lib/intl/msg-upsell-page.hooks'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

export enum MailingOption {
  MAIL_IT = 'MAIL_IT',
  IGNORE = 'IGNORE',
}

function useSelectionOptions() {
  const upsellYesButtonTitle = useMsgUpsellYesButtonTitle()
  const upsellYesButtonSubTitle = useMsgUpsellYesButtonSubTitle()
  const upsellNoButtonTitle = useMsgUpsellNoButtonTitle()
  return [
    {
      value: MailingOption.MAIL_IT,
      title: upsellYesButtonTitle,
      subTitle: upsellYesButtonSubTitle,
    },
    {
      value: MailingOption.IGNORE,
      title: upsellNoButtonTitle,
      subTitle: '---',
    },
  ]
}

export const BenefitSelection: FC<{ value: MailingOption; onChange: (newValue: MailingOption) => void }> = ({ value, onChange }) => {
  const options = useSelectionOptions()
  return (
    <>
      <Typography fontSize={'standard'} fontWeight="bold" mt={{ xs: 8 }} mb={{ md: 5, xs: 4 }}>
        <FormattedMessage defaultMessage="Would you like us to mail your postcards for you?" />
      </Typography>
      <SelectionSet skin="tiles-mini" onSelectedValueChange={v => onChange(v as MailingOption)} defaultSelectedValue={value}>
        {options.map(option => (
          <SelectionSetInput key={option.value} value={option.value}>
            <SelectionSetLabel backgroundColor={'standard'}>
              <SelectionSetTileContents>
                <SelectionSetTileName>{option.title}</SelectionSetTileName>
                <SelectionSetTilePrice>{option.subTitle}</SelectionSetTilePrice>
              </SelectionSetTileContents>
            </SelectionSetLabel>
          </SelectionSetInput>
        ))}
      </SelectionSet>
    </>
  )
}
