import { PageProps } from 'gatsby'
import { PagePattern } from 'lib/page-pattern'
import { PageName } from 'lib/telemetry'
import { FC, memo } from 'react'
import { PageContextType } from 'types/application.types'
import { UpsellPage } from './upsell.page'

const MemoizedPage = memo(UpsellPage)
const UpsellTemplate: FC<PageProps<unknown, PageContextType>> = ({ pageContext, location }) => (
  <PagePattern
    enforceSignIn={false}
    enforceSignInForWork={false}
    search={location.search}
    i18nMessages={pageContext.messages}
    localeCulture={pageContext.localeCulture}
    pageName={PageName.UPSELL_PAGE}
  >
    <MemoizedPage />
  </PagePattern>
)

export default UpsellTemplate
