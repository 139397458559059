import { Column, GridContainer, Hidden, Row } from '@vp/swan'
import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { WorkIdPreview } from 'components/work-id-preview/work-id-preview.component'
import { useNavigateToUpload } from 'hooks/navigate.hooks'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { PageLayout } from 'layouts/page/page.layout'
import { ErrorPageUnavailable } from 'lib/errors'
import { useMsgSeoDescriptionUpsellPage, useMsgSeoTitleUpsellPage } from 'lib/intl/msg-seo-info.hooks'
import { useMsgUpsellNextLabel } from 'lib/intl/msg-upsell-page.hooks'
import { useIsWorkConvertible } from 'lib/mailing-svc-api'
import { ProductInfoProvider } from 'lib/product-info'
import { useStudioPageURL } from 'lib/site-links'
import { PageName } from 'lib/telemetry'
import { PageNameForLogging } from 'lib/telemetry/tracking.types'
import { useWorkDetails } from 'lib/work-entity'
import { FC, useEffect, useMemo, useState } from 'react'
import { windowOpen } from 'utilities/navigate.utils'
import { ActionConvert } from '../components/action-convert/action-convert.component'
import { ActionSkip } from '../components/action-skip/action-skip.component'
import { BenefitSelection, MailingOption } from '../components/benefit-selection/benefit-selection.component'
import { UpsellBenefits } from '../components/upsell-benefits/upsell-benefits.component'

export const UpsellPage: FC<unknown> = () => {
  const seoTitleUpsellPage = useMsgSeoTitleUpsellPage()
  const seoDescriptionUpsellPage = useMsgSeoDescriptionUpsellPage()
  const { data: workDetails } = useWorkDetails()
  const { data: isConvertibleResponse, isSuccess: isCompatibleReceived } = useIsWorkConvertible()

  const [mailingOption, setMailingOption] = useState<MailingOption>(MailingOption.IGNORE)
  const navigateToUpload = useNavigateToUpload()
  const upsellNextLabel = useMsgUpsellNextLabel()
  const studioUrl = useStudioPageURL(true)
  const trackingProductPageName = useTrackingProductPageName(PageName.UPSELL_PAGE)

  useEffect(() => {
    // If the workId is already mailable
    const convertedPrd = isConvertibleResponse?.mailingServicesCoreProductId
    const prevPrd = workDetails?.product.key
    if (convertedPrd && prevPrd && convertedPrd === prevPrd) {
      navigateToUpload()
    }
  }, [workDetails, navigateToUpload, isConvertibleResponse?.mailingServicesCoreProductId])
  const product = useMemo(
    () => ({
      key: isConvertibleResponse?.mailingServicesCoreProductId,
      version: isConvertibleResponse?.mailingServicesProductVersion,
    }),
    [isConvertibleResponse],
  )

  let convertedWorkDetails = {}
  if (product.key && product.version && workDetails) {
    convertedWorkDetails = {
      ...workDetails,
      product: { key: product.key, version: product.version },
      merchandising: {
        ...workDetails.merchandising,
        merchandisingSelections: {
          ...workDetails.merchandising.merchandisingSelections,
          'Mailing Services': 'Mailable',
          'Address Service Option': 'Current Resident',
          Postage: 'First Class',
        },
      },
    }
  }

  return (
    <>
      <PageSeoTitle content={seoTitleUpsellPage} />
      <PageSeoDescription content={seoDescriptionUpsellPage} />
      <ProductInfoProvider product={product}>
        <PageLayout
          pageName={trackingProductPageName}
          pageNameForLogging={PageNameForLogging.UPSELL_PAGE}
          backPressText={upsellNextLabel}
          onBack={() => windowOpen(studioUrl)}
          nextElem={
            <>
              <ActionConvert isCompatible={isConvertibleResponse?.isCompatible} mailingOption={mailingOption} />
              <ActionSkip mailingOption={mailingOption} isCompatible={isConvertibleResponse?.isCompatible} isCompatibleReceived={isCompatibleReceived} />
            </>
          }
        >
          <GridContainer pt={{ xs: 8, sm: 9, md: 10 }}>
            <Row>
              <Column span={5} spanMd={5} spanSm={12}>
                <UpsellBenefits isCompatible={isConvertibleResponse?.isCompatible} isCompatibleReceived={isCompatibleReceived} />
                <BenefitSelection value={mailingOption} onChange={setMailingOption} />
              </Column>
              <Column span={6} spanMd={6} offset={1} offsetSm={0} spanSm={12}>
                <Hidden xs sm>
                  <WorkIdPreview purpose="merchandising.cross-sell.tile" workDetails={convertedWorkDetails} />
                </Hidden>
              </Column>
            </Row>
          </GridContainer>
          {isCompatibleReceived && <ErrorPageUnavailable show={!isConvertibleResponse?.isCompatible} errCode="ERROR: IS_NOT_COMPATIBLE" />}
        </PageLayout>
      </ProductInfoProvider>
    </>
  )
}
